<template>

  <!-- Style Parameters-->
  <div v-if="isStyle">

    <ws-accordion
        :items="styleNavigationSelect"
    >
      <template #item.design>
        <div class="pa-3 fill-height">
          <preview-element
              @click="element.config.style = item.value; updateSettings()"
              v-for="(item , i) in designSelect.list" :key="'design' + i"
              :element="item.element"
              :width="238"
              :block-style="blockStyle"
              :class="[{'mt-2' : i > 0}]"
              :is-selected="element.config.style === item.value"
          />
        </div>

      </template>
      <template #item.color>
        <element-style-settings
            @input="updateSettings"
            v-model="element.config"
            :element="element"
            :block-style="blockStyle"
        />
      </template>
      <template #item.font>
        <element-style-settings
            @input="updateSettings"
            v-model="element.config"
            :element="element"
            fonts
        />
      </template>
    </ws-accordion>
  </div>
  <!-- Format Parameters-->
  <div v-else-if="isFormat">

    <template  v-if="element.config.style === 'rows'">
      <h5>{{ $t('avalon.list.rows.proportion') }}</h5>
      <ws-button-group
          @input="updateSettings"
          v-model="element.config.list_proportion"
          :items="orientationSelect"
          class="mb-2 mt-2"
      />
    </template>

    <template  v-if="element.config.style.includes('grid')">
      <h5>{{ $t('NumberOfColumns') }}</h5>
      <ws-button-group
          @input="updateSettings"
          v-model="element.config.grid_columns"
          :items="girIconsRowsSelect"
          class="mb-2 mt-2"
      />

      <div v-if="['grid_icons_top','grid_numbers'].includes(element.config.style)" class="mt-5">
        <h5>{{ $t('avalon.list.rows.content_horizontal_align') }}</h5>
        <ws-button-group
            @input="updateSettings"
            v-model="element.config.content_align_horizontal"
            :items="imageOnTopHorizontalSelect"
            class="mb-2 mt-2 "
        />
      </div>



    </template>


    <a-parameter-slider
        v-if="element.config.style !== 'simple_list'"
        @change="updateThickness"
        class="mt-4"
        :value="element.config.thickness + 1"
        :default="0"
        :value-color="wsACCENT"
        label="LineWidth"
        light
        min="0"
        max="4"
    />
    <a-parameter-slider
        v-if="['grid_icons','grid_icons_top'].includes(element.config.style)"
        @change="element.config.image_size = $event; updateSettings() "
        class="mt-4"
        v-model="element.config.image_size"
        :default="100"
        :value-color="wsACCENT"
        label="ImageSize"
        light
        min="1"
        max="300"
    />

    <a-parameter-slider
        v-if="['grid_icons' , 'grid_icons_top' , 'grid_numbers'].includes(element.config.style)"
        @change="$emit('input' , this.COPY(this.element.config) )"
        class="mt-4"
        v-model="element.config.image_margin"
        :default="16"
        :value-color="wsACCENT"
        light
        :label="`avalon.list.rows.image_margin${element.config.style === 'grid_numbers' ? '_number' : ''}`"
        min="1"
        max="120"
    />

    <a-parameter-slider
        v-if="element.config.style.includes('grid')"
        @change="$emit('input' , this.COPY(this.element.config) )"
        class="mt-4"
        v-model="element.config.heading_margin"
        :default="16"
        :value-color="wsACCENT"
        light
        label="avalon.list.rows.heading_margin"
        min="1"
        max="120"
    />

    <a-parameter-slider
        @change="$emit('input' , this.COPY(element.config) )"
        class="mt-4"
        v-model="element.config.gutter_vertical"
        :default="0"
        :value-color="wsACCENT"
        light
        :label="`avalon.list.rows.vertical_padding${element.config.style.includes('grid') ? '_grid' : ''}`"
        min="1"
        max="120"
    />

    <a-parameter-slider
        v-if="(!element.config.style.includes('grid') || element.config.grid_columns > 1)"
        @change="updateSettings"
        class="mt-4"
        v-model="element.config.gutter"
        :default="0"
        :value-color="wsACCENT"
        :label="`avalon.list.rows.horizontal_padding${element.config.style.includes('grid') ? '_grid' : ''}`"
        light
        min="0"
        max="100"
    />






  </div>
  <!-- Content Parameters-->
  <div v-else>

    <div v-if="element.config.style === 'simple_list'">
      <image-param-picker
          @input="updateSettings"
          v-model="element.config.list_image"
      />
    </div>

    <block-settings-navigation
        v-model="selectedContentCategory"
        :items="navigationSelect"
        custom
        portal
    >
      <template #custom>
        <draggable
            v-model="element.config.elements"
            :forceFallback="true"
            :empty-insert-threshold="120"
            v-bind="opt"
            @start="drag = true"
            @change="updateSettings"
            handle=".topic-handle"
            @end="drag = false">

          <transition-group type="transition" :name="!drag ? 'flip-list' : null">
            <v-hover
                #default="{hover}"
                v-for="(item,index) in element.config.elements" :key="index"
            >
              <v-sheet
                  @click="selectedItemIndex = index ; selectedContentCategory='edit_item'"
                  :style="`box-shadow : 0 0 0 ${hover ? 2 : 1}px ${hover ? wsACCENT : wsBACKGROUND}`"
                  class="pointer d-flex justify-space-between align-center wsRoundedLight  mt-2 pr-3"
                  height="48"
              >
                <div class="d-flex align-center shorten-text">
                  <v-sheet min-width="24" style="cursor: grab;"  class="topic-handle"  color="transparent">
                    <v-icon v-if="hover"  :color="wsACCENT">mdi-drag-vertical</v-icon>
                  </v-sheet>

                  <h5 class="shorten-text" style="font-size: 12px; " >
                    {{ item.title || item.text || $t('AddItemTitle') }}
                  </h5>
                </div>
                <div v-if="hover" class="d-flex align-center">
                  <v-btn
                      @click.stop="duplicateItem(index)"
                      small icon :color="wsDARKLIGHT">
                    <v-icon size="18">mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn
                      @click.stop="deleteItem(index)"
                      small icon :color="wsDARKLIGHT">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </div>

              </v-sheet>
            </v-hover>
          </transition-group>
        </draggable>
        <portal to="element_settings_footer">
          <ws-button
              class="mb-2"
              @click="addItem"
              label="Add"
              block
          />
        </portal>

      </template>
      <template #item.edit_item>

        <ws-text-field
            @change="updateSettings"
            v-model="element.config.elements[selectedItemIndex].title"
            :label="$t('Title')"
            area
        />
        <ws-text-field
            class="mt-4"
            @change="updateSettings"
            v-model="element.config.elements[selectedItemIndex].text"
            :label="$t('Subtitle')"
            area
        />

        <ws-accordion
            v-if="element.config.style.includes('grid_icons')"
            class="mt-6"
            :items="imageNavigationSelectItems">
          <template #item.image>
            <image-param-picker
                @input="updateSettings"
                class="pa-4"
                v-model="element.config.elements[selectedItemIndex].img"
            />
          </template>
        </ws-accordion>
      </template>

    </block-settings-navigation>
  </div>
</template>

<script>


import draggable from "vuedraggable";
import elementStyleSettings from "@/components/AvalonEditor/Dialogs/elements/elementStyleSettings";
import previewElement from "@/components/AvalonEditor/Templates/previewElement";
import blockSettingsNavigation from "@/components/AvalonEditor/Dialogs/blocks/UI/blockSettingsNavigation";
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";

import elements_design_config from "@/assets/json/avalon/elements_design_config.json"

export default {
  name: "avalonTextSettings",
  components : {
    draggable,
    previewElement,
    blockSettingsNavigation ,
    imageParamPicker ,
    elementStyleSettings
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    isFormat : {
      type : Boolean,
      default : false
    },
    element : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    }
  },
  data() {
    return {
      selectedContentCategory : null,
      selectedItemIndex : null,
      config : {},
      drag : false,
      designSelect : [],
    }
  },
  computed : {
    imageNavigationSelectItems() {
      return [{name : this.$t('Image') , value : 'image'}]
    },
    styleNavigationSelect() {
      return [
        { name : this.$t('Design') , value : 'design' , expanded : false },
        { name : this.$t('Color') , value : 'color' },
        { name : this.$t('Fonts') , value : 'font' }
      ]
    },
    navigationSelect() {
      return [
        { text: this.$t('EditItem'), value: 'edit_item', hidden: true}
      ]
    },
    orientationSelect() {
      return [
        { text : '1 : 1' , value : '1-1' },
        { text : '2 : 3' , value : '2-3' },
      ]
    },
    imageOnTopSelect() {
      return [
        { text : this.$t('Left') , value : null},
        { text : this.$t('Top') , value : true},
      ]
    },
    imageOnTopHorizontalSelect() {
      return [
        { text : this.$t('AlignLeft') , value : 'start' },
        { text : this.$t('AlignCenter') , value : 'center' },
        { text : this.$t('AlignRight') , value : 'end' },
      ]
    },

    girIconsRowsSelect() {
      let items =  [
        { text : '1' , value : 1 },
        { text : '2' , value : 2 },
      ]
      if ( ['grid_numbers','grid_icons_top'].includes(this.element.config.style) ) {
        items.push(  { text : '3' , value : 3 })
        items.push(  { text : '4' , value : 4 })
      }

      return items
    },
    girIconsNumbersSelecr() {
      return [
        { text : '1' , value : 1 },
        { text : '2' , value : 2 },
        { text : '3' , value : 3 },
        { text : '4' , value : 4 },
      ]
    }
  },
  methods : {
    addItem() {
      this.element.config.elements.push({
        title : this.$t('EnterTitle'),
        text : this.$t('EnterText')
      })
    },
    updateThickness(value) {
      this.element.config.thickness = value - 1
      this.updateSettings()
    },
    updateSettings() {
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))
    },
    deleteItem(index) {
      this.element.config.elements.splice(index,1)
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))
    },
    duplicateItem(index) {
      let item =  this.COPY(this.element.config.elements[index])
      this.element.config.elements.splice(index , 0 , item)
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))
    },
  },
  async mounted() {
    this.designSelect =  elements_design_config
    this.config = this.COPY(this.value)
  }

}
</script>

<style scoped>
</style>